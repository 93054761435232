<template>
  <v-container>
    <v-row v-if="pageIsLoading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Getting data . . .
        </h3>
      </v-col>
    </v-row>

    <v-alert
      v-show="objective_status_is_submitted"
      transition="fade-transition"
      color="success"
      type="success"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px"
    >
      Successfully updated!
    </v-alert>

    <v-alert
      v-show="pageIsError"
      transition="fade-transition"
      color="error"
      type="error"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px"
    >
      An error occurred!
    </v-alert>

    <v-row v-if="!pageIsLoading">
      <v-col class="d-flex align-center">
        <label class="text-h5">
          <span>
            {{ stg_obj.stg_itm.kbn_stg.stage_name }}/ {{ stg_obj.title }}/
          </span>
          <span v-if="kanban_type === 'Deal'" class="grey--text">
            ( {{ stg_obj.stg_itm.deal.public_name }} )
          </span>
          <span v-if="kanban_type === 'Lender on boarding'" class="grey--text">
            ( {{ stg_obj.stg_itm.company.name }} )
          </span>
        </label>
      </v-col>
    </v-row>

    <v-row v-if="!pageIsLoading">
      <v-col>
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel class="bordered">
            <v-expansion-panel-header class="d-flex align-center">
              <div>
                <v-icon>mdi-bullseye-arrow</v-icon> Objective Information
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="white--text text-center" :width="200">FIELDS</th>
                    <th class="white--text text-center">DETAILS</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>OBJECTIVE NUMBER</td>
                    <td class="pl-7">
                      <v-label>{{ stg_obj.id }}</v-label>
                    </td>
                  </tr>

                  <tr>
                    <td>TITLE</td>
                    <td>
                      <v-text-field
                        v-model="stg_obj.title"
                        flat
                        solo
                        dense
                        hide-details
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>DESCRIPTION</td>
                    <td>
                      <v-text-field
                        v-model="stg_obj.description"
                        rows="1"
                        flat
                        solo
                        dense
                        hide-details
                        auto-grow
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>COUNT</td>
                    <td class="pl-7">
                      <v-label>
                        {{ stg_obj.count }}
                        {{ stg_obj.count > 1 ? "days" : "day" }}</v-label
                      >
                    </td>
                  </tr>

                  <tr>
                    <td>DUE DATE</td>
                    <td>
                      <v-text-field
                        v-model="stg_obj.new_day_limit"
                        type="date"
                        flat
                        solo
                        dense
                        hide-details
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>ACTIVITY DATE</td>
                    <td>
                      <v-text-field
                        v-model="stg_obj.activity_date"
                        type="date"
                        flat
                        solo
                        dense
                        hide-details
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>STATUS</td>
                    <td>
                      <v-select
                        v-model="stg_obj.status"
                        :items="status_options"
                        class="my-2"
                        dense
                        flat
                        hide-details
                        solo
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>PREDECESSOR</td>
                    <td>
                      <v-select
                        v-model="stg_obj.predecessor"
                        :items="predecessors"
                        class="my-2"
                        dense
                        flat
                        hide-details
                        solo
                      />
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="bordered">
            <v-expansion-panel-header>
              <div><v-icon>mdi-clipboard-text-outline</v-icon> Tasks</div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-row>
                <v-col class="task-style d-flex">
                  <v-card
                    v-for="(task, index) in stg_obj.stg_obj_tsks"
                    :key="task.id"
                    :disabled="isTaskDisabled(index)"
                    class="ml-5"
                    style="min-width: 350px; max-width: 350px"
                  >
                    <v-card-title>
                      {{ task.title }}
                      <v-spacer />
                      <v-btn
                        title="See Details"
                        @click="viewTask(task.id)"
                        icon
                      >
                        <v-icon> mdi-eye-outline </v-icon>
                      </v-btn>
                    </v-card-title>

                    <v-card-text>
                      <div>{{ task.description }}</div></v-card-text
                    >

                    <v-card-text>
                      <div>
                        <v-icon>mdi-white-balance-sunny</v-icon> Day Count:
                        {{ getDays(task.createdAt) }}
                        <v-icon class="ml-5">mdi-calendar-clock</v-icon> Due
                        Date: {{ task.new_day_limit }}
                      </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-title>Status</v-card-title>

                    <v-card-text>
                      <v-chip-group
                        v-model="task.status"
                        active-class="teal darken-4"
                        @change="updateTaskStatus(task.id, task.status)"
                        column
                      >
                        <v-chip
                          v-for="value in task_status_options"
                          :key="value"
                          :value="value"
                        >
                          {{ value }}</v-chip
                        >
                      </v-chip-group>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <!-- <v-file-input
          class="mb-5" prepend-icon="" append-icon="mdi-paperclip" label="Attach Files"
          show-size counter multiple
        /> -->

        <v-textarea
          v-model="new_note"
          label="Add Note (optional)"
          auto-grow
          outlined
        />

        <v-btn
          class="mr-5"
          title="Go back to kanban stage"
          @click="$router.go(-1)"
        >
          Back
        </v-btn>

        <v-btn
          :disabled="objective_status_is_updating"
          @click="updateObjective"
        >
          <v-progress-circular
            v-show="objective_status_is_updating"
            class="mr-2"
            color="primary"
            indeterminate
          />
          Update Objective
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div><v-icon>mdi-update</v-icon> UPDATE HISTORY:</div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-data-table
                :headers="note_headers"
                :items="this.stg_obj.notes"
                class="elevation-1"
                style="background-color: #111"
              >
                <template v-slot:item.createdAt="{ item }">
                  {{ formatDate(item.createdAt) }}
                </template>

                <template v-slot:item.status="{ item }">
                  {{ item.status }}
                </template>

                <template v-slot:item.note="{ item }">
                  {{ item.note }}
                </template>

                <template v-slot:item.user="{ item }">
                  {{ item.user.username }}
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";

export default {
  props: ["kanban_type", "stage_item_id", "stage_objective_id"],

  data: () => ({
    pageIsLoading: true,
    pageIsError: false,
    objective_status_is_updating: false,
    objective_status_is_submitted: false,
    isFieldFocused: false,
    isEditingObjective: false,

    new_note: "",

    stg_itm: {},

    stg_obj: {},

    predecessors: [{ value: 0, text: "None" }],

    panel: [0, 1],

    task_status_options: ["To Do", "On Progress", "Done"],

    status_options: ["To Do", "On Progress", "Done", "Archived"],

    note_headers: [
      { text: "Date", value: "createdAt" },
      { text: "Status", value: "status" },
      { text: "Note", value: "note" },
      { text: "User", value: "user" },
    ],
  }),

  computed: {
    inputWidth() {
      return `${this.stg_obj.day_limit.toString().length * 40}px`; // Adjust the multiplication factor as needed
    },
  },

  methods: {
    isTaskDisabled(index) {
      // disable task if the predecessor is not yet done
      const task_is_okay_to_open = this.stg_obj.stg_obj_tsks.some((task) => {
        if (task.order === this.stg_obj.stg_obj_tsks[index].predecessor) {
          return task.status !== "Done";
        }

        if (this.stg_obj.stg_obj_tsks[index].predecessor === 0) {
          return false;
        }
      });

      return task_is_okay_to_open;
    },

    viewTask(task_id) {
      this.$router.push({
        path: `/dashboard/admin/kanban/${this.kanban_type}/stage-item/${this.stage_item_id}/objective/${this.stage_objective_id}/task/read/${task_id}`,
      });
    },

    getDays(created_date) {
      // strapi date format to mm/dd/yyyy
      const dateFormatted = this.formatDate(created_date);

      // specify the start date in mm/dd/yyyy format
      const startDate = new Date(dateFormatted);

      // get the current system date
      const endDate = new Date();

      // calculate the time difference between the two dates
      const timeDiff = endDate.getTime() - startDate.getTime();

      // convert the time difference to days
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      return daysDiff;
    },

    formatDate(date_value) {
      // create a new Date object from the string
      const dateObj = new Date(date_value);

      // extract the date components from the date object
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1; // add 1 since month is zero-based
      const day = dateObj.getDate();

      // format the date components as mm/dd/yyyy
      const formattedDate = `${month}/${day}/${year}`;

      return formattedDate;
    },

    convertObjectiveDayLimit() {
      const date = new Date(this.stg_obj.createdAt);
      date.setDate(date.getDate() + this.stg_obj.day_limit);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const result = `${year}-${month}-${day}`;
      this.stg_obj.new_day_limit = result;
    },

    convertTaskDayLimit() {
      this.stg_obj.stg_obj_tsks.forEach((task) => {
        const date = new Date(task.createdAt);
        date.setDate(date.getDate() + task.day_limit);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const result = `${month}-${day}-${year}`;
        task.new_day_limit = result;
      });
    },

    async updateTaskStatus(task_id, status) {
      try {
        // find the task
        const task = this.stg_obj.stg_obj_tsks.find(
          (task) => task.id === task_id
        );

        // update the task status
        task.status = status;

        // update the task
        const update_task_res = await API().patch(
          "api/kanban/update_stage_objective_task",
          {
            user_id: this.$store.getters["Auth/getAuthUser"].id,
            stg_obj_task: task,
            stg_itm_id: this.stg_obj.stg_itm.id,
            note: "",
          }
        );

        if (update_task_res.status === 201) console.log(update_task_res.data);
      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
        }, 2000);
      }
    },

    async updateObjective() {
      try {
        this.objective_status_is_updating = true;

        // count from created date to new_day_limit
        // strapi date format to mm/dd/yyyy
        const dateFormatted = this.formatDate(this.stg_obj.createdAt);

        // specify the start date in mm/dd/yyyy format
        const startDate = new Date(dateFormatted);

        // get the day limit
        const endDate = new Date(this.stg_obj.new_day_limit);

        // calculate the time difference between the two dates
        const timeDiff = endDate.getTime() - startDate.getTime();

        // convert the time difference to days
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        // update the day limit
        this.stg_obj.day_limit = daysDiff;

        const update_res = await API().patch("api/kanban/update_stage_objective", {
          user_id: this.$store.getters["Auth/getAuthUser"].id,
          stg_obj: this.stg_obj,
          stg_itm_id: this.stg_obj.stg_itm.id,
          note: this.new_note,
        });

        console.log(update_res);

        if (update_res.status === 201) this.stg_obj.notes.push(update_res.data);

        this.objective_status_is_updating = false;
        this.objective_status_is_submitted = true;
        this.new_note = "";

        setTimeout(() => {
          this.objective_status_is_submitted = false;
        }, 2000);
      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
          this.objective_status_is_updating = false;
        }, 2000);
      }
    },

    async readStageObjective() {
      try {
        this.pageIsLoading = true;
        // get stage objective data
        const stg_obj_res = await API().get(
          `api/kanban/read_stage_objective/?stg_obj_id=${this.stage_objective_id}`
        );
        console.log('stage objective: ', stg_obj_res);

        if (stg_obj_res.status === 200) {
          this.stg_obj = stg_obj_res.data;
          this.convertObjectiveDayLimit();
          this.convertTaskDayLimit();
          this.stg_obj.count = this.getDays(this.stg_obj.createdAt);
          this.stg_obj.task = this.stg_obj.stg_obj_tsks.length;
        }

        const read_stg_itm = await API().get(
          `api/kanban/read_stage_item/?stg_itm_id=${this.stage_item_id}`
        );

        this.stg_itm = read_stg_itm.data;

        this.stg_itm.stg_objs.forEach((objective) => {
          if (objective.id !== Number(this.stage_objective_id)) {
            this.predecessors.push({
              value: objective.order,
              text: objective.title,
            });
          }
        });

        console.log("stage item: ", this.stg_itm);

        this.pageIsLoading = false;
      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
        }, 2000);
      }
    },
  },

  mounted() {
    this.readStageObjective();
  },
};
</script>

<style lang="scss">
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
  padding: 5px;
}

.task-style {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

/* height */
.task-style::-webkit-scrollbar {
  height: 10px;
}

/* Track */
.task-style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
.task-style::-webkit-scrollbar-thumb {
  background: rgb(97, 97, 97);
  border-radius: 10px;
}

/* Handle on hover */
.task-style::-webkit-scrollbar-thumb:hover {
  background: rgb(49, 49, 49);
}

.circle .v-input--selection-controls__ripple {
  border-radius: 50%;
}

.circle .v-input--selection-controls__input {
  border-radius: 50%;
}

.circle .v-icon {
  border-radius: 50%;
}

.custom-text-field input {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  outline: none;
}
</style>
